<template>
  <expandable-card class="inventory">
    <slot />

    <v-form
      ref="form"
      class="flex-grow-1 pt-3"
      :class="{ 'col-3': isCardExpanded, 'overflow-auto': isCardExpanded }"
    >
      <segment-width-form />

      <label>{{ $t('views.inventory.assetTypes') }}</label>
      <assets-selector />

      <div class="row ma-0 gap">
        <assets-fieldset
          v-for="asset in selectedAssets"
          ref="assetFields"
          :key="asset"
          :asset="asset"
        />
      </div>

      <div class="row ma-0">
        <v-btn
          :block="isCardExpanded"
          :disabled="disabled"
          elevation="1"
          color="success"
          class="mr-6 mt-3"
          @click="save"
          >{{ $t('views.inventory.save') }}</v-btn
        >
        <v-btn
          :block="isCardExpanded"
          :disabled="disabled"
          elevation="1"
          color="success"
          class="mr-6 mt-3"
          @click="saveAndApply"
          >{{ $t('views.inventory.saveAndApply') }}</v-btn
        >
        <v-btn
          :block="isCardExpanded"
          :disabled="disabled"
          elevation="1"
          color="warning"
          class="mr-6 mt-3"
          @click="reset"
          >{{ $t('views.inventory.reset') }}</v-btn
        >

        <v-dialog
          v-model="dialog"
          width="50%"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              :block="isCardExpanded"
              :disabled="disabled"
              elevation="1"
              color="danger"
              class="mr-6 mt-3 white--text"
              v-bind="attrs"
              v-on="on"
              >{{ $t('views.inventory.delete') }}</v-btn
            >
          </template>

          <v-card>
            <v-card-title class="text-h5">
              {{ $t('views.inventory.deleteAssetTitle') }}
            </v-card-title>

            <v-divider />

            <v-card-text class="text-subtitle-1">
              {{ $t('views.inventory.deleteAssetWarning') }}
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                outlined
                @click="dialog = false"
              >
                {{ $t('views.inventory.cancel') }}
              </v-btn>

              <v-spacer />

              <v-btn
                color="danger"
                class="white--text"
                @click="remove"
              >
                {{ $t('views.inventory.delete') }}
                <v-icon
                  dark
                  right
                  size="20"
                >
                  $far fa-trash-can
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-form>
  </expandable-card>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import AssetsFieldset from './AssetsFieldset.vue'
import AssetsSelector from './AssetsSelector.vue'
import ExpandableCard from '@/components/core/ExpandableCard.vue'
import SegmentWidthForm from './SegmentWidthForm.vue'

export default {
  name: 'AssetsForm',

  components: {
    AssetsFieldset,
    AssetsSelector,
    ExpandableCard,
    SegmentWidthForm,
  },

  data() {
    return {
      applyNext: false,
      dialog: false,
    }
  },

  computed: {
    ...mapGetters(['isCardExpanded', 'userId', 'idproject']),
    ...mapState('iri', ['segment']),
    ...mapState('inventory', ['assets', 'selectedAssets']),
    ...mapState('images', ['currentImage']),

    currentImageId() {
      return this.currentImage?._id
    },

    disabled() {
      return !this.selectedAssets.length || !this.currentImageId
    },
  },

  watch: {
    currentImageId(imageId) {
      if (this.assets[imageId]) {
        this.loadFromStore(imageId)
      } else if (!this.applyNext) {
        this.setSelectedAssets([])
      }
    },
  },

  methods: {
    ...mapActions('inventory', ['createAsset', 'deleteAsset', 'updateAsset']),
    ...mapMutations('inventory', ['setSelectedAssets', 'addToSelectedAssets']),

    update() {
      if (!this.$refs.form.validate()) {
        return false
      }

      const assetsData = {}

      this.$refs.assetFields.forEach((fieldSet) => {
        const data = fieldSet.collectData()
        assetsData[data.assetId] = data.data
      })

      if (this.assets[this.currentImageId]) {
        const imageId = this.currentImageId
        const assetId = this.assets[this.currentImageId].id
        this.updateAsset({ imageId, assetId, assetsData })
      } else {
        const payload = {
          projectId: this.idproject,
          imageId: this.currentImageId,
          measurementId: this.segment._id,
          userId: this.userId,
          assets: assetsData,
        }

        this.createAsset(payload)
      }

      return true
    },

    save() {
      if (this.update()) {
        this.$emit('next')
      }
    },

    saveAndApply() {
      if (this.update()) {
        this.applyNext = true
        this.$emit('next')
        this.$nextTick(() => {
          this.applyNext = false
        })
      }
    },

    reset() {
      this.loadFromStore(this.currentImageId)
    },

    remove() {
      this.setSelectedAssets([])
      this.dialog = false
      this.deleteAsset(this.currentImageId)
    },

    loadFromStore(imageId) {
      this.setSelectedAssets([])

      const assets = this.assets[imageId]?.assets

      if (!assets) {
        return
      }

      for (const asset in assets) {
        this.addToSelectedAssets(asset)
        this.$nextTick(() => {
          const fieldSet = this.$refs.assetFields.find(
            (field) => field.asset === asset,
          )
          if (fieldSet) {
            fieldSet.setValues(assets[asset])
          }
        })
      }
    },
  },
}
</script>
