<template>
  <v-wait for="loadingDashboard">
    <core-spinner slot="waiting" />
    <title-bar
      :show-back="true"
      :show-title="false"
      :custom-title="segment?.name"
    />

    <div
      class="grid-container"
      :class="{ 'expandable-card-container': isCardExpanded }"
    >
      <iri-map
        v-model="mapBindings"
        class="map"
      >
        <template #map-content>
          <iri-run :runs="iriMixin_iriRunGeo" />
          <section-highlight :section="iriMixin_selectedSectionGeo" />
          <image-cluster
            :images="iriMixin_orderedImages"
            :selected="currentImage?._id"
            @click:image="imageSelected"
            @hook:mounted="imageSelected"
          />
        </template>
      </iri-map>

      <inventory-table
        class="table"
        :segment="iriRun"
        @section-selected="iriMixin_sectionSelected"
      >
        <defects-download
          v-if="isDefects"
          :segment="iriRun"
          :ordered-images="iriMixin_orderedImages"
        />
        <assets-download
          v-else
          :segment="iriRun"
          :ordered-images="iriMixin_orderedImages"
        />
      </inventory-table>

      <defects-form
        v-if="isDefects"
        class="form"
        @next="next"
      >
        <image-viewer
          ref="imageViewer"
          class="flex-shrink-0 d-flex flex-column"
          :class="{ 'col-9': isCardExpanded }"
        />
      </defects-form>

      <assets-form
        v-else
        class="form"
        @next="next"
      >
        <image-viewer
          ref="imageViewer"
          class="flex-shrink-0 d-flex flex-column"
          :class="{ 'col-9': isCardExpanded }"
        />
      </assets-form>
    </div>
  </v-wait>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { latLng } from 'leaflet'

import AssetsDownload from './AssetsDownload.vue'
import AssetsForm from './AssetsForm.vue'
import DefectsDownload from './DefectsDownload.vue'
import DefectsForm from './DefectsForm.vue'
import ImageCluster from '@/components/core/map/ImageCluster.vue'
import ImageViewer from '@/components/DashViews/PopUps/ImageViewer'
import InventoryTable from './InventoryTable.vue'
import IriMap from '@/components/DashViews/Charts/Maps/IriMap'
import IriRun from '@/components/core/map/IriRun.vue'
import SectionHighlight from '@/components/core/map/SectionHighlight.vue'
import TitleBar from '@/components/core/TitleBar'

import iriMixin from '@/mixins/iriMixin'
import { defaultMapBindings } from '@/utils/map'

export default {
  name: 'InventoryDashboard',

  components: {
    AssetsDownload,
    AssetsForm,
    DefectsDownload,
    DefectsForm,
    ImageCluster,
    ImageViewer,
    InventoryTable,
    IriMap,
    IriRun,
    SectionHighlight,
    TitleBar,
  },

  mixins: [iriMixin],

  props: {
    measurementId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedSection: undefined,
      mapBindings: defaultMapBindings(),
    }
  },

  computed: {
    ...mapGetters(['isCardExpanded']),
    ...mapState('images', ['currentImage']),
    ...mapState('iri', ['segment']),
    ...mapState('images', {
      images: 'items',
    }),

    isDefects() {
      return this.$route.meta.isDefects
    },

    iriRun() {
      return this.segment
        ? {
            ...this.segment,
            iriSections: this.segment.iriSections,
            iriSections10m: this.segment.iriSections10m,
          }
        : null
    },
  },

  watch: {
    currentImage(currentImage) {
      const coordinates = currentImage?.datapoint?.location?.coordinates
      if (coordinates) {
        const center = latLng(coordinates[1], coordinates[0])
        this.mapBindings.center = center
      }
    },
  },

  async created() {
    this.$wait.start('loadingDashboard')
    await Promise.all([
      this.getMeasurement({
        measurementId: this.measurementId,
      }),
    ])

    if (this.isDefects) {
      await this.fetchDefects(this.measurementId)
    } else {
      await this.fetchAssets(this.measurementId)
    }
    this.iriMixin_setBounds()
    this.$wait.end('loadingDashboard')
  },

  beforeDestroy() {
    // Prevent bug when switching from measurement w/ images to empty one
    this.reset()
    this.setCurrentImage(null)
  },

  methods: {
    ...mapActions('iri', ['getMeasurement']),
    ...mapActions('inventory', ['fetchDefects', 'fetchAssets']),
    ...mapMutations('images', ['setCurrentImage']),
    ...mapMutations('inventory', ['reset']),

    imageSelected(item) {
      if (item) {
        this.$refs.imageViewer.openMultiple(
          this.iriMixin_orderedImages,
          this.iriMixin_orderedImages.findIndex((i) => i._id === item._id),
        )
      } else {
        this.$refs.imageViewer.openMultiple(this.iriMixin_orderedImages, 0)
      }
    },

    next() {
      this.$refs.imageViewer.next()
    },
  },
}
</script>

<style scoped>
.map {
  grid-row: 1 / span 6;
  grid-column: 1 / span 4;
}

.table {
  grid-row: 7 / span 6;
  grid-column: 1 / span 4;
}

.form {
  grid-row: 1 / span 12;
  grid-column: 5 / span 8;
  display: grid;
}
</style>
