<template>
  <fieldset class="px-3 flex-grow-1">
    <legend>{{ asset }}</legend>

    <v-text-field
      v-model.trim="referenceNo"
      :label="$t('views.inventory.referenceNo')"
      class="px-0"
      :rules="referenceNoRule"
    />

    <v-radio-group
      v-model="condition"
      class="mt-0"
      :label="$t('views.inventory.condition')"
      :rules="conditionRule"
      row
      dense
    >
      <v-radio
        v-for="n in 5"
        :key="n"
        :label="n.toString()"
        :value="n"
        :color="conditionColors[n - 1]"
      />
    </v-radio-group>

    <v-radio-group
      v-model="roadSide"
      class="mt-0"
      :label="$t('views.inventory.roadSide')"
      :rules="roadSideRule"
      row
      dense
    >
      <v-radio
        :label="$t('enums.side.0')"
        value="left"
      />
      <v-radio
        :label="$t('enums.side.1')"
        value="right"
      />
    </v-radio-group>

    <v-radio-group
      v-model="isOffRoad"
      class="mt-0"
      :label="$t('views.inventory.isOffRoad')"
      row
      dense
    >
      <v-radio
        :label="$t('views.inventory.onRoad')"
        :value="false"
      />
      <v-radio
        :label="$t('views.inventory.offRoad')"
        :value="true"
      />
    </v-radio-group>

    <label>{{ $t('views.inventory.roadSideDistance') }}</label>
    <v-range-slider
      v-model="roadSideDistance"
      :max="max"
      min="0"
      dense
      thumb-label
      :disabled="disabled"
    />

    <label>{{ $t('views.inventory.vehicleDistance') }}</label>
    <v-range-slider
      v-model="vehicleDistance"
      max="10"
      min="0"
      dense
      thumb-label
    />
  </fieldset>
</template>

<script>
import { mapState } from 'vuex'

import { rules } from '@/utils/rules'

export default {
  name: 'AssetsFieldset',

  props: {
    asset: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      referenceNo: this.asset,
      condition: null,
      roadSide: null,
      isOffRoad: null,
      disabled: false,
      roadSideDistance: [0, 10],
      vehicleDistance: [0, 10],
      conditionColors: ['#05a57d', '#33cc99', '#ffbd21', '#ff9400', '#ff6666'],

      conditionRule: rules.inventory.condition,
      roadSideRule: rules.inventory.roadSide,
      referenceNoRule: rules.inventory.referenceNo,
    }
  },

  computed: {
    ...mapState('iri', ['segment']),

    max() {
      return this.segment?.roadWidth || 10
    },
  },

  watch: {
    isOffRoad() {
      this.disabled = this.isOffRoad === true
      if (this.disabled) {
        this.roadSideDistance = [1, 1]
      } else {
        this.roadSideDistance = [0, 10]
      }
    },
  },

  methods: {
    collectData() {
      return {
        assetId: this.asset,
        data: {
          referenceNo: this.referenceNo,
          condition: this.condition,
          isOffRoad: this.isOffRoad,
          roadSide: this.roadSide,
          roadSideDistance: this.roadSideDistance,
          vehicleDistance: this.vehicleDistance,
        },
      }
    },

    setValues(data) {
      this.referenceNo = data.referenceNo
      this.condition = data.condition
      this.isOffRoad = data.isOffRoad
      this.roadSide = data.roadSide
      this.roadSideDistance = data.roadSideDistance
      this.vehicleDistance = data.vehicleDistance
    },
  },
}
</script>
