<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>$fas fa-download</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
      >
        <v-btn
          text
          @click="downloadCsv(item)"
        >
          {{ item }}
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'
import { groupBy, round } from 'lodash'

import { arrayToCsv } from '@/utils/csv'
import { calculatePavedPCI, calculateGravelPCI } from '@/utils/pci'
import { roadCategories } from '@/utils/enum'

export default {
  name: 'DefectsDownload',

  props: {
    segment: {
      type: Object,
      required: true,
    },

    orderedImages: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      items: ['10m', '100m'],
      maxDefectsCount10m: 0,
      maxDefectsCount100m: 0,

      defectHeaders: ['type', 'severity', 'density'],

      headers: [
        {
          text: this.$t('enums.headers.roadName'),
          value: 'name',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.sectionStart'),
          value: 'startStation',
          align: 'left',
        },
        {
          text: this.$t('enums.headers.sectionEnd'),
          value: 'endStation',
          align: 'left',
        },
        {
          text: this.$t('enums.headers.lat'),
          value: 'endLatitude',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.lon'),
          value: 'endLongitude',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.IRI'),
          value: 'iri',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.rut'),
          value: 'rut',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.macrotexture'),
          value: 'macrotexture',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.pci'),
          value: 'pci',
          align: 'left',
          sortable: false,
        },
      ],
    }
  },

  computed: {
    ...mapState('inventory', ['defects']),

    csvHeader() {
      return this.generateHeaders(this.maxDefectsCount10m)
    },

    csvHeaders100m() {
      return this.generateHeaders(this.maxDefectsCount100m)
    },

    defectsOrderedByStation() {
      const result = {}
      const imagesGroupedByStation = groupBy(this.orderedImages, 'startStation')

      for (const [station, images] of Object.entries(imagesGroupedByStation)) {
        const stationDefects = []
        for (const image of images) {
          const imageDefects = this.defects[image._id]?.defects
          if (imageDefects) {
            for (const [defectType, defectBody] of Object.entries(
              imageDefects,
            )) {
              stationDefects.push({ ...defectBody, type: defectType })
            }
          }
        }

        if (stationDefects.length > 0) {
          result[station] = stationDefects
        } else {
          result[station] = undefined
        }
      }

      return result
    },

    isGravelRoad() {
      return this.segment.roadCategory === roadCategories.gravel
    },
  },

  methods: {
    downloadCsv(item) {
      const csvContent = this.toCsv(item)

      const link = document.createElement('a')
      link.setAttribute('href', csvContent)
      link.setAttribute('download', `${this.segment.name}_${item}.csv`)
      link.click()
      link.remove()
    },

    toCsv(item) {
      let PCIInfo

      if (this.isGravelRoad) {
        PCIInfo = calculateGravelPCI(this.defectsOrderedByStation, this.segment)
      } else {
        PCIInfo = calculatePavedPCI(this.defectsOrderedByStation, this.segment)
      }

      const [PCIs, maxDefectsCount] = PCIInfo
      const CSVBody = []

      this.maxDefectsCount10m = maxDefectsCount

      const hundredMeterSections = {}

      for (let i = 0; i < this.segment.iriSections10m.length; i++) {
        const iriSection = this.segment.iriSections10m[i]

        const { startStation, endStation, endStationInt, endLocation, iri } =
          iriSection

        const { name } = this.segment

        const key = Math.ceil(endStationInt / 100) * 100

        if (!hundredMeterSections[key]) {
          hundredMeterSections[key] = []
        }

        const section = {
          name,
          startStation,
          endStation,
          endStationInt,
          endLatitude: endLocation.coordinates[1],
          endLongitude: endLocation.coordinates[0],
          iri,
        }

        const PCI = PCIs[i]

        if (PCI) {
          const pci = PCI.pop()
          const defects = PCI

          hundredMeterSections[key].push({
            startStation,
            endStation,
            endLatitude: endLocation.coordinates[1],
            endLongitude: endLocation.coordinates[0],
            iri,
            pci,
            defects,
          })

          this.appendDefectsToSection(section, defects)

          section.pci = pci
        }

        CSVBody.push(section)
      }

      this.hundredMeterSections = hundredMeterSections

      if (item === '10m') {
        return (
          'data:text/csv;charset=utf-8,' +
          encodeURI(arrayToCsv(this.csvHeader, CSVBody))
        )
      }

      return this.toCsv100m()
    },

    toCsv100m() {
      const CSVBody = []
      const entries = Object.entries(this.hundredMeterSections)

      for (let i = 0; i < entries.length; i++) {
        const [, values] = entries[i]
        let pciSum = 0
        let hundredMeterDefects = []

        for (const value of values) {
          pciSum += value.pci
          hundredMeterDefects.push(...value.defects)
        }

        let pciAvg = null
        if (values.length > 0) {
          pciAvg = round(pciSum / values.length, 4)
        }

        const iriSection = this.segment.iriSections[i]

        const { startStation, endStation, endStationInt, endLocation, iri } =
          iriSection

        const { name } = this.segment

        const section = {
          name,
          startStation,
          endStation,
          endStationInt,
          endLatitude: endLocation.coordinates[1],
          endLongitude: endLocation.coordinates[0],
          iri,
          pci: pciAvg,
        }

        if (hundredMeterDefects.length > this.maxDefectsCount100m) {
          this.maxDefectsCount100m = hundredMeterDefects.length
        }

        this.appendDefectsToSection(section, hundredMeterDefects)

        CSVBody.push(section)
      }

      return (
        'data:text/csv;charset=utf-8,' +
        encodeURI(arrayToCsv(this.csvHeaders100m, CSVBody))
      )
    },

    generateHeaders(maxDefectsCount) {
      const headers = [...this.headers]

      for (let i = 0; i < maxDefectsCount; i++) {
        const index = i + 1

        for (const defectHeader of this.defectHeaders) {
          const text = `${this.$t(`views.inventory.${defectHeader}`)} ${index}`
          const value = defectHeader + index

          headers.push({
            text,
            value,
            align: 'left',
            sortable: false,
          })
        }
      }

      return headers
    },

    appendDefectsToSection(section, defects) {
      for (let i = 0; i < defects.length; i++) {
        const index = i + 1
        const defect = defects[i]

        for (const property of this.defectHeaders) {
          const key = property + index
          let value

          if (property === 'severity') {
            const { severity, severityText } = defect
            value = `${severity} (${this.$t(`enums.severity.${severityText}`)})`
          } else if (property === 'type') {
            value = this.$t(`enums.defects.${defect.type}`)
          } else {
            value = defect[property]
          }

          section[key] = value
        }
      }
    },
  },
}
</script>
