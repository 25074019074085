<template>
  <v-expansion-panels flat>
    <v-expansion-panel>
      <v-expansion-panel-header
        class="px-0"
        @click="panel = !panel"
        >{{ $t('views.inventory.segmentDetails') }}</v-expansion-panel-header
      >
      <v-expansion-panel-content>
        <v-form ref="form">
          <v-text-field
            v-model="roadWidth"
            class="px-0"
            :label="$t('enums.headers.roadWidth')"
            type="number"
            :rules="[roadWidthRule]"
            hide-details="auto"
          />
          <v-text-field
            v-model="shoulderWidth"
            class="px-0"
            :label="$t('enums.headers.shoulderWidth')"
            type="number"
            :rules="[shoulderWidthRule]"
            hide-details="auto"
          />
          <v-text-field
            v-model="bikeLaneWidth"
            class="px-0"
            :label="$t('enums.headers.bikeLaneWidth')"
            type="number"
            :rules="[shoulderWidthRule]"
            hide-details="auto"
          />

          <v-btn
            elevation="1"
            color="warning"
            class="mr-6 mt-3"
            @click="reset"
            >{{ $t('views.inventory.reset') }}</v-btn
          >
          <v-btn
            :disabled="disabled"
            :loading="loading"
            elevation="1"
            color="success"
            class="mr-6 mt-3"
            @click="save"
            >{{ $t('views.inventory.save') }}</v-btn
          >
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { isValidNumber } from '@/utils/math'

export default {
  data() {
    return {
      panel: false,
      roadWidth: null,
      shoulderWidth: null,
      bikeLaneWidth: null,
      loading: false,
    }
  },

  computed: {
    ...mapState('iri', ['segment']),

    segmentRoadWidth() {
      return this.segment?.roadWidth?.toString() || null
    },

    segmentShoulderWidth() {
      return this.segment?.shoulderWidth?.toString() || null
    },

    segmentBikeLaneWidth() {
      return this.segment?.bikeLaneWidth?.toString() || null
    },

    disabled() {
      const hasEmpty = [
        this.roadWidth,
        this.shoulderWidth,
        this.bikeLaneWidth,
      ].some((value) => !value)

      const noChange =
        this.roadWidth === this.segmentRoadWidth &&
        this.shoulderWidth === this.segmentShoulderWidth &&
        this.bikeLaneWidth === this.segmentBikeLaneWidth

      return hasEmpty || noChange
    },
  },

  mounted() {
    this.init()
  },

  methods: {
    ...mapActions('iri', ['patchMeasurement']),

    roadWidthRule(value) {
      if (this.panel && !isValidNumber(value, 1, 100, 1)) {
        return this.$t('api-error.iri.roadWidth-error')
      }
      return true
    },

    shoulderWidthRule(value) {
      if (this.panel && !isValidNumber(value, 0, 10, 1)) {
        return this.$t('api-error.iri.shoulderWidth-error')
      }
      return true
    },

    async save() {
      if (!this.$refs.form.validate()) {
        return
      }

      this.loading = true
      await this.patchMeasurement({
        id: this.segment._id,
        payload: {
          roadWidth: Number(this.roadWidth),
          shoulderWidth: Number(this.shoulderWidth),
          bikeLaneWidth: Number(this.bikeLaneWidth),
        },
      })
      this.loading = false
    },

    init() {
      this.roadWidth = this.segmentRoadWidth
      this.shoulderWidth = this.segmentShoulderWidth
      this.bikeLaneWidth = this.segmentBikeLaneWidth
    },

    reset() {
      this.init()
      this.$refs.form.resetValidation()
    },
  },
}
</script>
