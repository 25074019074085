<template>
  <expandable-card class="inventory">
    <slot />

    <v-form
      ref="form"
      class="flex-grow-1 pt-3"
      :class="{ 'col-3': isCardExpanded, 'overflow-auto': isCardExpanded }"
    >
      <segment-width-form />

      <label>{{ $t('views.inventory.defectTypes') }}</label>
      <v-select
        v-model="selectedDefects"
        :items="availableDefects"
        :no-data-text="$t('views.inventory.noData')"
        :disabled="!currentImageId"
        class="custom dense-select"
        chips
        deletable-chips
        hide-details="auto"
        multiple
      />

      <div class="row ma-0 gap">
        <defects-fieldset
          v-for="defect in selectedDefects"
          ref="defectFields"
          :key="defect"
          :defect="defect"
        />
      </div>

      <div class="row ma-0">
        <v-btn
          :block="isCardExpanded"
          :disabled="disabled"
          elevation="1"
          color="success"
          class="mr-6 mt-3"
          @click="save"
          >{{ $t('views.inventory.save') }}</v-btn
        >
        <v-btn
          :block="isCardExpanded"
          :disabled="disabled"
          elevation="1"
          color="success"
          class="mr-6 mt-3"
          @click="saveAndApply"
          >{{ $t('views.inventory.saveAndApply') }}</v-btn
        >
        <v-btn
          :block="isCardExpanded"
          :disabled="disabled"
          elevation="1"
          color="warning"
          class="mr-6 mt-3"
          @click="reset"
          >{{ $t('views.inventory.reset') }}</v-btn
        >
        <v-dialog
          v-model="dialog"
          width="50%"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              :block="isCardExpanded"
              :disabled="disabled"
              elevation="1"
              color="danger"
              class="mr-6 mt-3 white--text"
              v-bind="attrs"
              v-on="on"
              >{{ $t('views.inventory.delete') }}</v-btn
            >
          </template>

          <v-card>
            <v-card-title class="text-h5">
              {{ $t('views.inventory.deleteDefectTitle') }}
            </v-card-title>

            <v-divider />

            <v-card-text class="text-subtitle-1">
              {{ $t('views.inventory.deleteDefectWarning') }}
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                outlined
                @click="dialog = false"
              >
                {{ $t('views.inventory.cancel') }}
              </v-btn>

              <v-spacer />

              <v-btn
                color="danger"
                class="white--text"
                @click="remove"
              >
                {{ $t('views.inventory.delete') }}
                <v-icon
                  dark
                  right
                  size="20"
                >
                  $far fa-trash-can
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-form>
  </expandable-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import DefectsFieldset from './DefectsFieldset.vue'
import ExpandableCard from '@/components/core/ExpandableCard.vue'
import SegmentWidthForm from './SegmentWidthForm.vue'

import { defectTypes, roadCategories } from '@/utils/enum'

export default {
  name: 'DefectsForm',

  components: {
    DefectsFieldset,
    ExpandableCard,
    SegmentWidthForm,
  },

  data() {
    return {
      selectedDefects: [],
      applyNext: false,
      dialog: false,
    }
  },

  computed: {
    ...mapGetters(['isCardExpanded', 'userId', 'idproject']),
    ...mapState('iri', ['segment']),
    ...mapState('inventory', ['defects']),
    ...mapState('images', ['currentImage']),

    pavedDefects() {
      return [
        defectTypes.ALLIGATOR_CRACK,
        defectTypes.BLEEDING,
        defectTypes.BLOCK_CRACK,
        defectTypes.BUMP,
        defectTypes.CORRUGATION,
        defectTypes.DEPRESSION,
        defectTypes.EDGE_CRACK,
        defectTypes.LONGITUDINAL_CRACK,
        defectTypes.PATCHING,
        defectTypes.POTHOLES,
        defectTypes.RAVELING,
        defectTypes.RUT,
        defectTypes.SAGGING,
        defectTypes.TRANSVERSE_CRACK,
      ]
        .map((defect) => ({
          value: defect,
          text: this.$t(`enums.defects.${defect}`),
        }))
        .sort((a, b) => a.text.localeCompare(b.text))
    },

    gravelDefects() {
      return [
        defectTypes.CORRUGATION,
        defectTypes.CROWN,
        defectTypes.DITCH,
        defectTypes.DRAINAGE_ISSUE,
        defectTypes.DUSTY_SURFACE,
        defectTypes.HOMOGENEOUS_MATERIALS,
        defectTypes.LOOSE_GRAVEL,
        defectTypes.POTHOLES,
        defectTypes.RUT,
        defectTypes.SIDE_SLOPE,
        defectTypes.SHARP_ELEVATION,
        defectTypes.UNEVEN_GRAVEL,
        defectTypes.WASHING_OUT,
        defectTypes.WIDTH_VARIATION,
      ]
        .map((defect) => ({
          value: defect,
          text: this.$t(`enums.defects.${defect}`),
        }))
        .sort((a, b) => a.text.localeCompare(b.text))
    },

    concreteDefects() {
      return [
        defectTypes.ALLIGATOR_CRACK,
        defectTypes.CORNER_BREAKS,
        defectTypes.DRAINAGE_ISSUE,
        defectTypes.EDGE_BREAKS,
        defectTypes.LONGITUDINAL_CRACK,
        defectTypes.PATCHING,
        defectTypes.POPOUTS,
        defectTypes.RAVELING,
        defectTypes.SCALING,
        defectTypes.TRANSVERSE_CRACK,
      ]
        .map((defect) => ({
          value: defect,
          text: this.$t(`enums.defects.${defect}`),
        }))
        .sort((a, b) => a.text.localeCompare(b.text))
    },

    availableDefects() {
      const category = this.segment.roadCategory

      if (category === roadCategories.gravel) {
        return this.gravelDefects
      } else if (category === roadCategories.concrete) {
        return this.concreteDefects
      }
      return this.pavedDefects
    },

    currentImageId() {
      return this.currentImage?._id
    },

    disabled() {
      return !this.selectedDefects.length || !this.currentImageId
    },
  },

  watch: {
    currentImageId(imageId) {
      if (this.defects[imageId]) {
        this.loadFromStore(imageId)
      } else if (!this.applyNext) {
        this.selectedDefects = []
      }
    },
  },

  methods: {
    ...mapActions('inventory', [
      'createDefect',
      'deleteDefect',
      'updateDefect',
    ]),

    update() {
      if (!this.$refs.form.validate()) {
        return false
      }

      const defectsData = {}
      this.$refs.defectFields.forEach((fieldSet) => {
        const data = fieldSet.collectData()
        defectsData[data.defectId] = data.data
      })

      if (this.defects[this.currentImageId]) {
        const imageId = this.currentImageId
        const defectId = this.defects[this.currentImageId].id
        this.updateDefect({ imageId, defectId, defectsData })
      } else {
        const payload = {
          projectId: this.idproject,
          imageId: this.currentImageId,
          measurementId: this.segment._id,
          userId: this.userId,
          defects: defectsData,
        }

        this.createDefect(payload)
      }

      return true
    },

    save() {
      if (this.update()) {
        this.$emit('next')
      }
    },

    saveAndApply() {
      if (this.update()) {
        this.applyNext = true
        this.$emit('next')
        this.$nextTick(() => {
          this.applyNext = false
        })
      }
    },

    reset() {
      this.loadFromStore(this.currentImageId)
    },

    remove() {
      this.selectedDefects = []
      this.dialog = false
      this.deleteDefect(this.currentImageId)
    },

    loadFromStore(imageId) {
      this.selectedDefects = []

      const defects = this.defects[imageId]?.defects

      if (!defects) {
        return
      }

      for (const defect in defects) {
        this.selectedDefects.push(defect)
        this.$nextTick(() => {
          const fieldSet = this.$refs.defectFields.find(
            (field) => field.defect === defect,
          )
          if (fieldSet) {
            fieldSet.setValues(defects[defect])
          }
        })
      }
    },
  },
}
</script>
