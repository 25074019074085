/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */

export default [
  // This  allows you to have pages apart of the app but no rendered inside the dash
  {
    path: '/',
    meta: {
      title: 'core.meta-title.daway',
      name: '',
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "routes" */ '@/views/LoginHome.vue'),
    children: [
      {
        path: '',
        meta: {
          title: 'core.meta-title.login',
        },
        component: () => import('@/components/LoginForm.vue'),
      },
      {
        path: '/reset-password',
        alias: '/account-activation',
        props: (route) => ({
          token: route.query.token,
        }),
        meta: {
          name: 'core.drawer.links.client-dashboard',
          title: 'core.meta-title.login',
          requiresAuth: false,
        },
        component: () => import('@/components/PasswordReset.vue'),
      },
    ],
  },
  // add any extra routes that you want rendered in the dashboard as a child below. Change toolbar names here
  {
    path: '/administration/projects',
    meta: {
      name: 'core.drawer.links.projects-management',
      title: 'core.meta-title.admin',
      requiresAuth: true,
    },
    component: () => import('@/views/Projects.vue'),
  },
  {
    path: '/administration/project/:id',
    props: (route) => ({ projectId: route.params.id }),
    meta: {
      name: 'core.drawer.links.projects-management',
      title: 'core.meta-title.admin',
      requiresAuth: true,
    },
    component: () => import('@/views/ProjectTabs.vue'),
  },
  {
    path: '/administration/users',
    meta: {
      name: 'core.drawer.links.users',
      title: 'core.meta-title.admin',
      requiresAuth: true,
    },
    component: () => import('@/views/Users.vue'),
  },
  {
    path: '/administration/user/:id',
    props: (route) => ({ userId: route.params.id }),
    meta: {
      name: 'core.drawer.links.users',
      title: 'core.meta-title.admin',
      requiresAuth: true,
    },
    component: () => import('@/views/UserTabs.vue'),
  },
  {
    path: '/client',
    props: (route) => ({
      uuid: route.query.uuid,
      token: route.query.token,
    }),
    meta: {
      name: 'core.drawer.links.client-dashboard',
      requiresAuth: false,
      requiresWeather: true,
    },
    component: () => import('@/components/DashViews/Client/Client.vue'),
  },
  {
    path: '/dashboard',
    meta: {
      name: 'Dashboard View',
      title: 'core.meta-title.dash',
      requiresAuth: true,
    },
    component: () => import('@/views/DashboardView.vue'),
    children: [
      {
        path: 'site-control-technician',
        name: 'Tableau de bord de contrôle en chantier',
        props: (route) => ({
          project: route.query.project,
          user: route.query.user,
        }),
        meta: {
          name: 'core.drawer.links.site-control-technician',
          title: 'core.meta-title.site',
          requiresAuth: true,
          requiresWeather: true,
        },
        component: () =>
          import('@/components/DashViews/SiteTechnician/Main.vue'),
      },
      {
        path: 'site-control-technician/details',
        name: 'Détails de contrôle en chantier',
        props: (route) => ({ user: route.query.user }),
        meta: {
          name: 'core.drawer.links.site-control-technician',
          title: 'core.meta-title.site',
          requiresAuth: true,
          requiresWeather: true,
        },
        component: () =>
          import('@/components/DashViews/SiteTechnician/Details.vue'),
      },
      {
        path: 'project-manager',
        name: 'core.drawer.links.project-manager',
        meta: {
          name: 'core.drawer.links.project-manager',
          title: 'core.meta-title.site',
          requiresAuth: true,
        },
        component: () => import('@/components/DashViews/DashboardCDPCQ.vue'),
      },
      {
        path: 'production-technician-asphalt',
        name: 'core.drawer.links.production-technician-asphalt',
        props: (route) => ({
          mixDesign: route.query.mixDesign,
          plant: route.query.plant,
        }),
        meta: {
          name: 'core.drawer.links.production-technician-asphalt',
          title: 'core.meta-title.production',
          requiresAuth: true,
          requiresWeather: true,
        },
        component: () =>
          import(
            '@/components/DashViews/ProductionTechnician/AsphaltTechnician.vue'
          ),
      },
      {
        path: 'production-technician-aggregate',
        name: 'core.drawer.links.production-technician-aggregate',
        props: (route) => ({
          supplier: route.query.supplier,
          specification: route.query.specification,
          stockpile: route.query.stockpile,
        }),
        meta: {
          name: 'core.drawer.links.production-technician-aggregate',
          title: 'core.meta-title.production',
          requiresAuth: true,
          requiresWeather: true,
        },
        component: () =>
          import(
            '@/components/DashViews/ProductionTechnician/AggregateTechnician.vue'
          ),
      },
      {
        path: 'raw-materials',
        name: 'core.drawer.links.raw-materials',
        props: (route) => ({
          material: route.query.material,
          mixDesign: route.query.mixDesign,
          plant: route.query.plant,
        }),
        meta: {
          name: 'core.drawer.links.raw-materials',
          title: 'core.meta-title.production',
          requiresAuth: true,
          requiresWeather: true,
        },
        component: () =>
          import(
            '@/components/DashViews/ProductionTechnician/RawMaterials.vue'
          ),
      },
      {
        path: 'site-foreman',
        name: 'Tableau de bord de contremaitre de chantier',
        meta: {
          name: 'core.drawer.links.site-foreman',
          title: 'core.meta-title.site',
          requiresAuth: true,
          requiresWeather: true,
        },
        component: () =>
          import('@/components/DashViews/SiteForeman/SiteForeman.vue'),
      },
      {
        path: 'site-superintendent',
        name: 'core.drawer.links.site-superintendent',
        meta: {
          name: 'core.drawer.links.site-superintendent',
          title: 'core.meta-title.site',
          requiresAuth: true,
          requiresWeather: true,
        },
        component: () =>
          import('@/components/DashViews/SiteForeman/SiteSuperintendent.vue'),
      },
      {
        path: 'production-foreman',
        name: 'core.drawer.links.production-foreman',
        props: (route) => ({
          supplier: route.query.supplier,
          mixDesign: route.query.mixDesign,
          specification: route.query.specification,
          stockpile: route.query.stockpile,
        }),
        meta: {
          name: 'core.drawer.links.production-foreman',
          title: 'core.meta-title.production',
          requiresAuth: true,
          requiresWeather: true,
        },
        component: () =>
          import(
            '@/components/DashViews/ProductionForeman/ProductionForeman.vue'
          ),
      },
      {
        path: 'asphalt-foreman',
        name: 'core.drawer.links.asphalt-foreman',
        props: (route) => ({
          supplier: route.query.supplier,
          mixDesign: route.query.mixDesign,
        }),
        meta: {
          name: 'core.drawer.links.asphalt-foreman',
          title: 'core.meta-title.production',
          requiresAuth: true,
          requiresWeather: true,
        },
        component: () =>
          import('@/components/DashViews/ProductionForeman/AsphaltForeman.vue'),
      },
      {
        path: 'aggregate-foreman',
        name: 'core.drawer.links.aggregate-foreman',
        props: (route) => ({
          supplier: route.query.supplier,
          specification: route.query.specification,
          stockpile: route.query.stockpile,
        }),
        meta: {
          name: 'core.drawer.links.aggregate-foreman',
          title: 'core.meta-title.production',
          requiresAuth: true,
          requiresWeather: true,
        },
        component: () =>
          import(
            '@/components/DashViews/ProductionForeman/AggregateForeman.vue'
          ),
      },
      {
        path: 'site-foreman/road-comfort',
        name: 'Détail de comfort de roulement',
        alias: 'site-superintendent/road-comfort/',
        props: () => ({
          types: ['iri', 'images'],
        }),
        meta: {
          name: 'core.drawer.links.iri-measurements-management',
          title: 'core.meta-title.site',
          requiresAuth: true,
        },
        component: () =>
          import('@/components/DashViews/IRIManagement/Measurements/List.vue'),
      },
      {
        path: 'site-foreman/quality-control',
        name: 'Détail de contrôle qualité',
        alias: 'site-superintendent/quality-control/',
        meta: {
          name: (route) =>
            route.path === '/dashboard/site-foreman/quality-control'
              ? 'core.drawer.links.site-foreman-details'
              : 'core.drawer.links.site-superintendent-details',
          title: 'core.meta-title.site',
          requiresAuth: true,
        },
        component: () =>
          import('@/components/DashViews/SiteForeman/SiteForemanDetails.vue'),
      },
      {
        path: 'notifications',
        meta: {
          name: 'core.drawer.links.notifications',
          title: 'core.meta-title.notification',
          requiresAuth: true,
        },
        component: () => import('@/components/DashViews/Notification/Main.vue'),
      },
      {
        path: 'upload-image',
        meta: {
          name: 'core.drawer.links.upload-image',
          title: 'core.meta-title.image',
          requiresAuth: true,
        },
        component: () => import('@/components/DashViews/Image/ImageTabs.vue'),
      },
      {
        path: 'calibration-management',
        meta: {
          name: 'core.drawer.links.calibration-management',
          title: 'core.meta-title.iri',
          requiresAuth: true,
        },
        component: () =>
          import('@/components/DashViews/IRIManagement/Calibration/Main.vue'),
      },
      {
        path: 'calibration-management/nearby-benchmarks',
        meta: {
          name: 'core.drawer.links.nearby-benchmarks',
          title: 'core.meta-title.iri',
          requiresAuth: true,
        },
        component: () =>
          import(
            '@/components/DashViews/IRIManagement/Calibration/NearbyBenchmarks.vue'
          ),
      },
      {
        path: 'calibration-management/calibrations',
        meta: {
          name: 'core.drawer.links.calibrations',
          title: 'core.meta-title.iri',
          requiresAuth: true,
        },
        component: () =>
          import(
            '@/components/DashViews/IRIManagement/Calibration/Calibrations.vue'
          ),
      },
      {
        path: 'iri-measurements-management',
        meta: {
          name: 'core.drawer.links.iri-measurements-management',
          title: 'core.meta-title.iri',
          requiresAuth: true,
        },
        component: () =>
          import('@/components/DashViews/IRIManagement/Measurements/List.vue'),
      },
      {
        path: 'iri-images/:measurement',
        props: (route) => ({ measurementId: route.params.measurement }),
        meta: {
          name: 'core.drawer.links.iri-images',
          title: 'core.meta-title.iri',
          requiresAuth: true,
        },
        component: () =>
          import(
            '@/components/DashViews/IRIManagement/Measurements/IriImageUpload.vue'
          ),
      },
      {
        path: 'iri-measurements/:measurement',
        props: (route) => ({ measurementId: route.params.measurement }),
        meta: {
          name: 'core.drawer.links.iri-measurements-management',
          title: 'core.meta-title.iri',
          requiresAuth: true,
        },
        component: () =>
          import(
            '@/components/DashViews/IRIManagement/Measurements/Details.vue'
          ),
      },
      {
        path: 'inventory/defects/:measurement',
        props: (route) => ({ measurementId: route.params.measurement }),
        meta: {
          name: 'core.drawer.links.iri-measurements-management',
          title: 'core.meta-title.iri',
          isDefects: true,
          requiresAuth: true,
        },
        component: () =>
          import('@/components/DashViews/Inventory/InventoryDashboard.vue'),
      },
      {
        path: 'inventory/assets/:measurement',
        props: (route) => ({ measurementId: route.params.measurement }),
        meta: {
          name: 'core.drawer.links.iri-measurements-management',
          title: 'core.meta-title.iri',
          isDefects: false,
          requiresAuth: true,
        },
        component: () =>
          import('@/components/DashViews/Inventory/InventoryDashboard.vue'),
      },
      {
        path: 'iri-measurements-management/create',
        meta: {
          name: 'core.drawer.links.iri-measurements-creation',
          title: 'core.meta-title.iri',
          requiresAuth: true,
        },
        component: () =>
          import(
            '@/components/DashViews/IRIManagement/Measurements/Create.vue'
          ),
      },
      {
        path: 'reports',
        meta: {
          name: 'core.drawer.links.reports',
          title: 'core.meta-title.report',
          requiresAuth: true,
        },
        component: () => import('@/components/DashViews/reports/List.vue'),
      },
      {
        path: 'reports/create',
        meta: {
          name: 'core.drawer.links.create-report',
          title: 'core.meta-title.report',
          requiresAuth: true,
        },
        component: () => import('@/components/DashViews/reports/Create.vue'),
      },
      {
        path: 'reports/:report_id',
        props: (route) => ({ reportId: route.params.report_id }),
        meta: {
          name: 'core.drawer.links.reports',
          title: 'core.meta-title.report',
          requiresAuth: true,
        },
        component: () => import('@/components/DashViews/reports/Edit.vue'),
      },
      {
        path: 'general-manager-contractor',
        name: 'core.drawer.links.general-manager-contractor',
        meta: {
          name: 'views.generalManagerContractor.titleBarName',
          title: 'core.meta-title.dash',
          requiresAuth: true,
          requiresWeather: true,
        },
        component: () =>
          import(
            '@/components/DashViews/GeneralManager/GMContractorDashboard.vue'
          ),
      },
      {
        path: 'contractor',
        name: 'core.drawer.links.contractor',
        meta: {
          name: 'views.contractorDashboard.titleBarName',
          title: 'core.meta-title.dash',
          requiresAuth: true,
        },
        component: () =>
          import(
            '@/components/DashViews/ProjectManagers/Contractor/ContractorDashboard.vue'
          ),
      },
      {
        path: 'technical-manager',
        name: 'core.drawer.links.technical-manager',
        meta: {
          name: 'core.drawer.links.technical-manager',
          title: 'core.meta-title.dash',
          requiresAuth: true,
        },
        component: () =>
          import(
            '@/components/DashViews/TechnicalDirector/TechnicalManager.vue'
          ),
      },
    ],
  },
  {
    path: '/preferences',
    meta: {
      name: 'core.drawer.links.preferences',
      requiresAuth: true,
    },
    component: () => import('@/views/UnderConstruction.vue'),
  },
  {
    path: '/help',
    meta: {
      name: 'core.drawer.links.help',
      requiresAuth: true,
    },
    component: () => import('@/views/HelpPage.vue'),
  },
  {
    path: '*',
    meta: {
      name: 'NotFound',
      requiresAuth: true,
    },
    component: () => import('@/components/error/NotFound.vue'),
  },
]
